import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import CreateTask from "./CreateTask";
import "../../index.css";
import { DocumentData, Timestamp } from "firebase/firestore";
import {
  deleteTask,
  modifyTask,
  isUserProofed,
  markAsFinished,
} from "../../Backend/Projects/handleProject";
import { sendNotification } from "../../Backend/Mails/handleNotifications";
import TaskMembers from "./TaskMembers";
import Popup from "../../Components/Popup";
import Chat from "../../Components/Chat";

interface Props {
  project: DocumentData;
  status: string;
  task: DocumentData;
  admin: boolean;
}

type popup = {
  text: string;
  state: string;
};

export default function Example({ project, status, task, admin }: Props) {
  const [popup, setPopup] = useState<popup>({
    state: "success",
    text: "Invalid error",
  });
  const [open, setOpen] = useState<boolean>(false);
  const [, setIsLoading] = useState<boolean>(false);
  const [openM, setOpenM] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [isProofed, setIsProofed] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (openM || open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openM, open]);

  useEffect(() => {
    isUserProofed(task.members, setIsProofed);
  }, [task]);

  useEffect(() => {
    if (openChat) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
  }, [openChat]);

  const finishTask = () => {
    markAsFinished(project.id, task.id, setPopup, setIsVisible);
  };

  const changeTaskStatus = (method: string) => {
    let newStatus: string = "";
    if (method === "prev") {
      if (status === "doing") {
        newStatus = "toDo";
      } else if (status === "finished") {
        newStatus = "doing";
      }
    } else if (method === "next") {
      if (status === "toDo") {
        newStatus = "doing";
      } else if (status === "doing") {
        newStatus = "finished";
      }
    }

    if (newStatus === "finished") {
      finishTask();
      return;
    }

    if (task.duplicate === null) {
      modifyTask(
        task.id,
        project,
        task.name,
        task.description,
        task.location,
        newStatus,
        setPopup,
        setIsVisible,
        setIsLoading,
        setOpen
      );
    } else if (task.duplicate !== null) {
      modifyTask(
        task.id,
        project,
        task.name,
        task.description,
        task.location,
        newStatus,
        setPopup,
        setIsVisible,
        setIsLoading,
        setOpen,
        task.end,
        task.start,
        task.duplicate
      );
    }
  };

  function addDaysToTimestamp(
    timestamp: Timestamp,
    daysToAdd: number
  ): Timestamp {
    const date = timestamp.toDate();
    date.setDate(date.getDate() + daysToAdd);

    return Timestamp.fromDate(date);
  }

  const duplicate = () => {
    const days = prompt(
      "Pendant combien de jours souhaitez-vous dupliquer cette tache avec les memes horaires ? (Reponse en jours)"
    );

    let duplicate;

    if (days !== null) {
      const parsedDays = parseInt(days);

      duplicate = {
        start: task.start,
        days: days,
        end: addDaysToTimestamp(task.start, parsedDays),
      };

      modifyTask(
        task.id,
        project,
        task.name,
        task.description,
        task.location,
        status,
        setPopup,
        setIsVisible,
        setIsLoading,
        setOpen,
        task.end,
        task.start,
        duplicate
      );
    }
  };

  const signale = () => {
    const reason = prompt("Quel est le motif de votre signalement ?");

    const result = window.confirm(
      `Voulez-vous signaler la tâche ${task.name} avec pour motif "${reason}" ?`
    );

    if (result) {
      const body = `La tâche "${task.name}" du projet "${project.name}" a été signalé avec pour motif "${reason}".`;
      sendNotification(body);
    }
  };

  const openProofs = () => {
    setOpenChat(true);
    setSelected("Justificatifs");
  };

  const deleteT = () => {
    const result = window.confirm(
      `Voulez-vous vraiment supprimer la tâche "${task.name}"?`
    );

    if (result) {
      deleteTask(project, task.id, setPopup, setIsVisible);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <CreateTask
        open={open}
        task={task}
        modify={true}
        status={status}
        setOpen={setOpen}
        project={project}
      />
      <TaskMembers
        task={task}
        open={openM}
        admin={admin}
        project={project}
        teams={task.teams}
        setOpen={setOpenM}
      />
      <Chat
        task={task}
        admin={admin}
        open={openChat}
        project={project}
        selected={selected}
        setOpen={setOpenChat}
        setSelected={setSelected}
      />
      <Popup isVisible={isVisible} infos={popup} />

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex w-full justify-center rounded-md focus-visible:ring-opacity-75">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[18px] h-[18px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 custom-shadow right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e) => setOpenChat(true)}
                    className={`${
                      active
                        ? "custom-shadow bg-gray-100 duration-200"
                        : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-[15px] h-[15px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                      />
                    </svg>
                    <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                      Chat
                    </label>
                  </button>
                )}
              </Menu.Item>
              {!admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      disabled={isProofed}
                      onClick={openProofs}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                        />
                      </svg>

                      <label
                        className={`${
                          isProofed && "italic"
                        } ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer`}
                      >
                        {!isProofed ? "Terminer & Justifier" : "Déja justifier"}
                      </label>
                    </button>
                  )}
                </Menu.Item>
              )}
              {admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={(e) => setOpen(true)}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                      <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                        Modifier
                      </label>
                    </button>
                  )}
                </Menu.Item>
              )}
              {admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      disabled={
                        status === "finished" || task.duplicate !== null
                      }
                      onClick={duplicate}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                        />
                      </svg>
                      {task.duplicate !== null ? (
                        <label className="ml-[7px] text-[14px] italic text-[#333] font-[500] cursor-pointer">
                          Déjà dupliquer
                        </label>
                      ) : (
                        <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                          Dupliquer
                        </label>
                      )}
                    </button>
                  )}
                </Menu.Item>
              )}
              {admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      disabled={status === "toDo"}
                      onClick={(e) => changeTaskStatus("prev")}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
                        />
                      </svg>

                      <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                        Etape précedante
                      </label>
                    </button>
                  )}
                </Menu.Item>
              )}
              {admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      disabled={status === "finished"}
                      onClick={(e) => changeTaskStatus("next")}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
                        />
                      </svg>

                      <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                        Etape suivante
                      </label>
                    </button>
                  )}
                </Menu.Item>
              )}
              {admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      disabled={status === "finished"}
                      onClick={finishTask}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>

                      <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                        Terminer la tache
                      </label>
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e) => setOpenM(true)}
                    className={`${
                      active
                        ? "custom-shadow bg-gray-100 duration-200"
                        : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-[15px] h-[15px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                      />
                    </svg>

                    <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                      {admin ? <span>Gérer</span> : <span>Voir</span>} les
                      participants
                    </label>
                  </button>
                )}
              </Menu.Item>
              {admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={deleteT}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                      <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                        Supprimer
                      </label>
                    </button>
                  )}
                </Menu.Item>
              )}
              {!admin && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={signale}
                      className={`${
                        active
                          ? "custom-shadow bg-gray-100 duration-200"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[15px] h-[15px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        />
                      </svg>

                      <label className="ml-[7px] text-[14px] text-[#333] font-[500] cursor-pointer">
                        Signaler cette tâche
                      </label>
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
