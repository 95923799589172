import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { DocumentData, Timestamp } from "firebase/firestore";
import moment from "moment";
import "moment/locale/fr";
import "../../index.css";
import CreateTask from "./CreateTask";
import { getProjectTasks } from "../../Backend/Projects/handleProject";
import { Toaster } from "sonner";

const localizer = momentLocalizer(moment);
moment.locale("fr");

interface Props {
  tasks: any[];
  open: boolean;
  admin: boolean;
  project: DocumentData;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalendarData = ({ open, setOpen, project, admin, tasks }: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isLoading] = useState<boolean>(false);
  const [openC, setOpenC] = useState<boolean>(false);
  const [modify, setModify] = useState<boolean>(false);
  const [task, setTask] = useState<object | undefined>();
  const [collections, setCollections] = useState<any>([]);
  const [fromLocation, setFromLocation] = useState<boolean>(false);

  const stringToTimestamp = (date: Date) => {
    return Timestamp.fromDate(date);
  };

  const timestampToDate = (timestamp: Timestamp) => {
    const seconds = timestamp.seconds;
    const milliseconds = timestamp.nanoseconds / 1000000;
    return new Date(seconds * 1000 + milliseconds);
  };

  useEffect(() => {
    setCollections([]);
    for (const task of tasks) {
      if (task.duplicate !== null) {
        const startDate = task.start.toDate();
        const endDate = task.end.toDate();
        const startHour = startDate.getHours();
        const startMinute = startDate.getMinutes();
        const startSecond = startDate.getSeconds();

        const currentDate = new Date(startDate);

        while (currentDate <= task.duplicate.end.toDate()) {
          const currentDateCopy = new Date(currentDate);

          currentDateCopy.setHours(startHour);
          currentDateCopy.setMinutes(startMinute);
          currentDateCopy.setSeconds(startSecond);

          const finalEndDate = new Date(
            currentDateCopy.getFullYear(),
            currentDateCopy.getMonth(),
            currentDateCopy.getDate(),
            endDate.getHours(),
            endDate.getMinutes(),
            endDate.getSeconds()
          );

          const data = {
            id: task.id,
            description: task.description,
            location: task.location,
            title: task.name,
            start: currentDateCopy,
            end: finalEndDate,
            duplicate: task.duplicate,
            status: task.status,
          };

          setCollections((old: any) => [...old, data]);

          currentDate.setDate(currentDate.getDate() + 1);
        }
      } else if (task.duplicate === null) {
        const data = {
          id: task.id,
          description: task.description,
          location: task.location,
          title: task.name,
          start: timestampToDate(task.start),
          end: timestampToDate(task.end),
          duplicate: task.duplicate,
          status: task.status,
        };

        setCollections((old: any) => [...old, data]);
      }
    }
  }, [tasks]);

  const selectEvent = (data: any) => {
    if (admin) {
      setTask({
        id: data.id,
        name: data.title,
        description: data.description,
        location: data.location,
        start: stringToTimestamp(data.start),
        end: stringToTimestamp(data.end),
        status: data.status,
        duplicate: data.duplicate,
      });

      setModify(true);
      setOpenC(true);
    }
  };

  const handleSelect = (data: any): boolean | void => {
    if (admin) {
      console.log(data);
      setTask({
        name: "",
        description: "",
        location: "",
        start: stringToTimestamp(data.start),
        end: stringToTimestamp(data.end),
        status: data.status,
        duplicate: data.duplicate,
      });

      setFromLocation(true);
      setOpenC(true);
      return true;
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setOpen]);

  return (
    <div
      className={`fixed flex items-center justify-center flex-col top-0 left-0 w-full h-[100vh] z-[1002] 
        ${open ? "show-window" : "hide-window"}
      `}
    >
      <Toaster position="top-center" richColors />
      <div className="abolsute w-full min-h-[100vh] z-[900] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>

      {!isLoading && admin && (
        <CreateTask
          open={openC}
          setOpen={setOpenC}
          project={project}
          status=""
          task={task}
          setTask={setTask}
          fromLocation={fromLocation}
          setFromLocation={setFromLocation}
          modify={modify}
          setModify={setModify}
        />
      )}
      <div className="flex items-center justify-center w-auto h-auto fixed bottom-[30px] z-[1001]">
        <button
          onClick={(e) => setOpen(false)}
          className="flex items-center justify-center px-[1em] w-auto h-[40px] rounded-full bg-red-500"
        >
          <label className="text-[16px] cursor-pointer text-white font-[400] mr-[6px]">
            Fermer le calendrier
          </label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[15px] h-[15px] fill-white text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div
        className={`blur-bg z-[999] overflow-hidden overflow-y-auto fixed bottom-0 md:top-[50%] md:left-[50%] w-full h-[80%] md:w-[100%] md:h-[100vh] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[5px]`}
      >
        <div className="w-full h-full">
          <Calendar
            views={["day", "week", "agenda", "work_week", "month"]}
            selectable
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="week"
            events={collections}
            style={{ height: "100vh" }}
            onSelectEvent={selectEvent}
            onSelectSlot={handleSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarData;
