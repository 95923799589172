import React, { useState, useRef, useEffect } from "react";
import {
  getTeamUsers,
  removeTeamFromProject,
} from "../../Backend/Projects/handleProject";
import { DocumentData } from "firebase/firestore";
import {
  getAllTeams,
  getAllOrgMembers,
  addUserToProject,
  addTeamToProject,
  changeMemberRole,
  removeFromProject,
} from "../../Backend/Projects/handleProject";
import "../../index.css";
import Popup from "../../Components/Popup";
import Loader from "../../Components/Loader";
import { toast } from "sonner";

interface Props {
  admin: boolean;
  members: member[];
  teamsMembers: any[];
  open: boolean;
  project: DocumentData;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  teams?: any[];
}

type member = {
  id: string;
  name: string;
  picture: string;
  nickname: string;
  email: string;
  role: string;
};

type popup = {
  text: string;
  state: string;
};

function HandleMembers({
  teams,
  members,
  teamsMembers,
  project,
  open,
  setOpen,
  admin,
}: Props) {
  const divRef = useRef<HTMLDivElement>(null);
  const [popup, setPopup] = useState<popup>({
    state: "success",
    text: "Invalid error",
  });
  const [orgMembers, setOrgMembers] = useState<any[]>([]);
  const [teamsData, setTeamsData] = useState<DocumentData[]>([]);
  const [membersData, setMembersData] = useState<member[]>();

  const [selected, setSelected] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredMembers, setFilteredMembers] = useState<member[]>([]);

  useEffect(() => {
    getAllTeams(setTeamsData, setIsLoading);
    getAllOrgMembers(setOrgMembers, setIsLoading);
  }, []);

  useEffect(() => {
    setMembersData(members);
    if (teams !== undefined) {
      for (const team of teams) {
        getTeamUsers(team.id, setMembersData);
      }
    }
  }, [members, teams]);

  useEffect(() => {
    if (!open) {
      setSelected([]);
      setSearchTerm("");

      let elements = document.getElementsByClassName("common-for-person");

      let elementsArray = Array.from(elements);

      elementsArray.forEach((element) => {
        element.classList.remove("bg-green-100");
        element.querySelector(".person-selected")?.classList.remove("flex");
        element.querySelector(".person-selected")?.classList.add("hidden");
      });
    }
  }, [open]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setOpen]);

  const removeU = (id: string) => {
    if (admin) {
      const result = window.confirm(
        "Voulez-vous supprimer ce membre de ce projet ?"
      );

      if (result) {
        removeFromProject(id, project.id, setPopup, setIsVisible);
      }
    }
  };

  const changeRole = (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: string,
    role: string
  ) => {
    const result = window.confirm(
      "Voulez-vous changer le rôle de cet utilisateur?"
    );

    let newRole;

    if (role === "Manager") {
      newRole = "Membre";
    } else {
      newRole = "Manager";
    }

    if (result) {
      changeMemberRole(id, project, newRole, setPopup, setIsVisible);
    }
  };

  useEffect(() => {
    if (members && members.length !== 0 && searchTerm.length !== 0) {
      const filtered = members.filter(
        (item: member) =>
          item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMembers(filtered);
    } else {
      setFilteredMembers([]);
    }

    if (searchTerm.length === 0) {
      setFilteredMembers([]);
    }
  }, [searchTerm, members]);

  //   const changeRole = (
  //     e: React.ChangeEvent<HTMLSelectElement>,
  //     id: string,
  //     role: string
  //   ) => {
  //     const result = window.confirm(
  //       "Voulez-vous changer le role de cet utilisateur?"
  //     );

  //     let newRole;

  //     if (role === "Manager") {
  //       newRole = "Membre";
  //     } else {
  //       newRole = "Manager";
  //     }

  //     if (result) {
  //       changeUserRole(id, team, newRole, setPopup, setIsVisible);
  //     }
  //   };

  const select = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    obj: any
  ) => {
    let target = e.currentTarget;
    target.classList.toggle("bg-green-100");

    if (target.classList.contains("bg-green-100")) {
      setSelected((old) => old.filter((item) => item.id !== obj.id));

      target.querySelector(".person-selected")?.classList.remove("hidden");
      target.querySelector(".person-selected")?.classList.add("flex");
    } else if (!target.classList.contains("bg-green-100")) {
      setSelected((old: any[]) => [...old, obj]);

      target.querySelector(".person-selected")?.classList.remove("flex");
      target.querySelector(".person-selected")?.classList.add("hidden");
    }

    if (target.classList.contains("bg-green-100")) {
      setSelected((old: any[]) => [...old, obj]);
    } else if (!target.classList.contains("bg-green-100")) {
      setSelected((old) => old.filter((item) => item.id !== obj.id));
    }
  };

  const add = () => {
    if (admin) {
      const result = window.confirm(
        `Voulez vraiment ajouter les ${selected.length} membres sélectionné(e)(s) dans le projet "${project.name}"?`
      );

      if (result) {
        let i = 0;
        selected.forEach((obj) => {
          if (obj.type === "member") {
            if (i !== selected.length - 1) {
              addUserToProject(obj.id, project);
            } else {
              addUserToProject(obj.id, project, setSelected, setOpen);
            }
          }

          if (obj.type === "team") {
            if (i !== selected.length - 1) {
              addTeamToProject(obj.id, project);
            } else {
              addTeamToProject(obj.id, project, setSelected, setOpen);
            }
          }

          i++;
        });

        // toast.success("Le(s) membre(s) sélectionné(s) ont bien été ajouté(s).");
      }
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-[100vh] z-[1002] 
    ${open ? "show-window" : "hide-window"}
      `}
    >
      <div className="abolsute w-full min-h-[100vh] z-[900] backdrop-blur-[1.5px] bg-[#e2e2e233]"></div>
      <div
        ref={divRef}
        className={`members-handle blur-bg z-[999] overflow-hidden overflow-y-auto fixed bottom-0 md:top-[50%] md:left-[50%] w-full h-[80%] md:w-[45%] md:h-[60%] md:max-w-[475px] md:max-h-[450px] md:translate-x-[-50%] md:translate-y-[-50%] flex items-center justify-center bg-white custom-shadow rounded-[5px]`}
      >
        <Popup isVisible={isVisible} infos={popup} />
        <div className="for-members-handle overflow-auto relative flex items-center justify-start flex-col w-full h-full">
          {selected.length > 0 && (
            <button
              disabled={!admin}
              onClick={add}
              className="fixed z-[9999] animate-bounce duration-[0.4s] bottom-[15px] rounded-[50px] w-auto h-[40px] px-[10px] flex items-center justify-center bg-green-600"
            >
              {isLoading ? (
                <Loader />
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <label className="text-[15px] text-white mr-[8px] hover:cursor-pointer">
                    Ajouter le(s) membres(s)
                  </label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-fill-up w-[20px] h-[20px] text-white"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                  </svg>
                </div>
              )}
            </button>
          )}
          {/* SEARCH */}
          <div className="search-part relative w-full min-h-[50px] flex items-start justify-start border-b-[1.25px] border-gray-300">
            <div className="search-part-icon flex items-center justify-center w-[45px] h-full mr-[8px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search w-[15px] h-[15px] text-gray-500"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Rechercher un membre dans l'organisation."
              className="w-[calc(100%-55px)] border-none outline-none h-full placeholder:text-[15px] text-[16px]"
            />
            <button
              onClick={(e) => setSearchTerm("")}
              className="del-part-icon bg-white cursor-pointer flex items-center justify-center w-[45px] h-full absolute right-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="bi bi-search w-[15px] h-[15px] text-red-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
          {/* SHOW */}
          <div className="description-label mt-[5px] w-[95%]">
            {selected.length > 0 ? (
              <label className="text-[13px] text-green-500">
                {selected.length} membre(s) sélectioné(s)
              </label>
            ) : (
              <label className="text-[13px] text-gray-500">
                Cliquer sur un nom pour le selectionner
              </label>
            )}
          </div>
          {/* ALL SECTIONS */}
          <div className="members-all flex items-start justify-start flex-col w-[100%] h-auto mt-[0.5em]">
            {filteredMembers.length === 0 && (
              <section className="section flex items-center justify-start flex-col w-full h-auto">
                <div className="section-name w-[95%] text-[14px] text-[#333] font-[500]">
                  <label>Membres du projet</label>
                </div>
                <div className="section-list mt-[0.4em] flex items-center justify-start flex-col w-full h-auto">
                  {membersData !== undefined && membersData.length > 0 ? (
                    <React.Fragment>
                      {membersData.map((member: member) => (
                        <button
                          key={member.id}
                          className="common-for-person hover:bg-gray-100 hover:cursor-pointer duration-[0.3s] py-[8px] flex items-center justify-center w-full h-auto"
                        >
                          <div className="w-[95%] h-full flex items-center justify-between">
                            <div className="person-details flex items-center justify-start w-auto h-auto">
                              <div className="person-picture flex items-center justify-center w-[35px] h-[35px] rounded-[50%] overflow-hidden">
                                {member.picture === "" && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                                    />
                                  </svg>
                                )}
                                {member.picture !== "" && (
                                  <img
                                    className="w-full h-full object-cover"
                                    src={member.picture}
                                    alt="person-pdp"
                                  />
                                )}
                              </div>

                              <div className="person-txt ml-[14px] flex items-center justify-start w-auto h-auto">
                                <label className="text-[15px] text-[#333] font-[500]">
                                  {member.name}
                                </label>
                                <label className="ml-[5px] text-[12.5px] text-gray-500 font-[400]">
                                  @{member.nickname}
                                </label>
                              </div>
                            </div>
                            <div className="flex items-center justify-center w-auto h-auto z-[600]">
                              <select
                                disabled={!admin}
                                onChange={(e) =>
                                  changeRole(e, member.id, member.role)
                                }
                                value={member.role}
                                className="w-auto h-[35px] ml-[] hover:cursor-pointer bg-black text-white text-[14px] md:px-[7px] rounded-[2px] outline-none"
                              >
                                <option value="Membre">Membre</option>
                                <option value="Manager">Manager</option>
                              </select>
                              {admin && (
                                <button
                                  disabled={!admin}
                                  onClick={() => removeU(member.id)}
                                  className="delete-icon flex items-center justify-center ml-[10px] hover:cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-person-fill-dash w-[18px] h-[18px] hover:text-red-500 text-gray-500"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1Zm0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                                  </svg>
                                </button>
                              )}
                            </div>

                            {/* <div className="person-selected  "></div> */}
                          </div>
                        </button>
                      ))}
                      {/*  */}
                      {teamsMembers.map((member: any) => (
                        <button
                          key={member.id}
                          className="common-for-person disabled:hover:bg-transparent hover:bg-gray-100 hover:cursor-pointer duration-[0.3s] py-[8px] flex items-center justify-center w-full h-auto"
                        >
                          <div className="w-[95%] h-full flex items-center justify-between">
                            <div className="person-details flex items-center justify-start w-auto h-auto">
                              <div className="person-picture flex items-center justify-center w-[35px] h-[35px] bg-gray-200 rounded-[50%] overflow-hidden">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 text-[#333]"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                  />
                                </svg>
                              </div>

                              <div className="ml-[14px] flex items-center justify-start w-auto h-auto">
                                <label className="text-[15px] text-[#333] font-[500]">
                                  {member.name}
                                </label>
                              </div>
                            </div>
                            {admin && (
                              <button
                                disabled={!admin}
                                onClick={(e) =>
                                  removeTeamFromProject(member.id, project.id)
                                }
                                className="delete-icon flex items-center justify-center ml-[10px] hover:cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-person-fill-dash w-[18px] h-[18px] hover:text-red-500 text-gray-500"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1Zm0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                  <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                                </svg>
                              </button>
                            )}
                          </div>
                        </button>
                      ))}
                    </React.Fragment>
                  ) : (
                    <div className="flex items-center justify-start w-[95%] h-auto">
                      <label className="text-gray-400 text-[12px]">
                        Aucun membre dans le projet
                      </label>
                    </div>
                  )}
                </div>
              </section>
            )}
            {filteredMembers.length === 0 && (
              <section className="section my-[1.5em] flex items-center justify-start flex-col w-full h-auto">
                <div className="section-name w-[95%] text-[14px] text-[#333] font-[500]">
                  <label>Equipes de l'organisation</label>
                </div>
                <div className="section-list mt-[0.4em] flex items-center justify-start flex-col w-full h-auto">
                  {teamsData !== undefined && teamsData.length > 0 ? (
                    <React.Fragment>
                      {teamsData.map((member: any) => (
                        <button
                          key={member.id}
                          disabled={
                            project.teams.some(
                              (obj: member) => obj.id === member.id
                            ) || !admin
                          }
                          onClick={(e) =>
                            select(e, { id: member.id, type: "team" })
                          }
                          className="common-for-person disabled:hover:bg-transparent hover:bg-gray-100 hover:cursor-pointer duration-[0.3s] py-[8px] flex items-center justify-center w-full h-auto"
                        >
                          <div className="w-[95%] h-full flex items-center justify-between">
                            <div className="person-details flex items-center justify-start w-auto h-auto">
                              <div className="person-picture flex items-center justify-center w-[35px] h-[35px] bg-gray-200 rounded-[50%] overflow-hidden">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 text-[#333]"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                  />
                                </svg>
                              </div>

                              <div className="person-txt ml-[14px] flex items-center justify-start w-auto h-auto">
                                <label className="text-[15px] text-[#333] font-[500]">
                                  {member.name}
                                </label>
                              </div>
                            </div>

                            <div className="person-selected hidden">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-circle-fill text-green-700"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                            </div>

                            <div
                              className={`person-already flex items-center justify-end ${
                                project.teams.some(
                                  (obj: member) => obj.id === member.id
                                )
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <label className="text-[13px] italic text-[#00000074] font-[400] mr-[7px]">
                                Déja membre
                              </label>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 -960 960 960"
                                className="bi bi-check-circle-fill w-[18px] h-[18px] fill-[#00000074]"
                              >
                                <path d="M480-106q-77 0-145-29.25t-119.25-80.5Q164.5-267 135.25-335T106-480q0-78 29.25-145.5t80.5-118.75Q267-795.5 335-824.75T480-854q78 0 145.5 29.25t118.75 80.5Q795.5-693 824.75-625.5T854-480q0 77-29.25 145t-80.5 119.25Q693-164.5 625.5-135.25T480-106Zm0-67q127.5 0 217.25-89.75T787-480q0-55-18-103.5T719-672L288-241q40 32 88.5 50T480-173ZM241-288l431-431q-40-32-88.5-50T480-787q-127.5 0-217.25 89.75T173-480q0 55 18 103.5t50 88.5Z" />
                              </svg>
                            </div>
                          </div>
                        </button>
                      ))}
                    </React.Fragment>
                  ) : (
                    <div className="flex items-center justify-start w-[95%] h-auto">
                      <label className="text-gray-400 text-[12px]">
                        Aucune equipe dans le projet
                      </label>
                    </div>
                  )}
                </div>
              </section>
            )}

            <section className="section flex items-center justify-start flex-col w-full h-auto">
              <div className="section-name w-[95%] text-[14px] text-[#333] font-[500]">
                <label>Membres de l'organisation</label>
              </div>
              <div className="section-list mt-[0.4em] flex items-center justify-start flex-col w-full h-auto">
                {filteredMembers.length > 0 &&
                  filteredMembers.map((obj: member) => (
                    <button
                      key={obj.id}
                      onClick={(e) => select(e, obj.id)}
                      className="common-for-person hover:bg-gray-100 hover:cursor-pointer duration-[0.3s] py-[8px] flex items-center justify-center w-full h-auto"
                    >
                      <div className="w-[95%] h-full flex items-center justify-between">
                        <div className="person-details flex items-center justify-start w-auto h-auto">
                          <div className="person-picture flex items-center justify-center w-[35px] h-[35px] rounded-[50%] overflow-hidden">
                            {obj.picture.length === 0 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                                />
                              </svg>
                            )}
                            {obj.picture.length > 0 && (
                              <img
                                className="w-full h-full object-cover"
                                src={obj.picture}
                                alt="person-pdp"
                              />
                            )}
                          </div>

                          <div className="person-txt ml-[14px] flex items-center justify-start w-auto h-auto">
                            <label className="text-[15px] text-[#333] font-[500]">
                              {obj.name}
                            </label>
                            <label className="ml-[5px] text-[12.5px] text-gray-500 font-[400]">
                              @{obj.nickname}
                            </label>
                          </div>
                        </div>

                        <div className="person-selected hidden">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-check-circle-fill text-green-700"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                        </div>
                      </div>
                    </button>
                  ))}

                {filteredMembers.length === 0 && (
                  <React.Fragment>
                    {orgMembers.length > 0 ? (
                      <React.Fragment>
                        {orgMembers.map((member: member) => (
                          <button
                            key={member.id}
                            disabled={
                              project.members.some(
                                (obj: member) => obj.id === member.id
                              ) || !admin
                            }
                            onClick={(e) =>
                              select(e, { id: member.id, type: "member" })
                            }
                            className="common-for-person disabled:hover:bg-transparent hover:bg-gray-100 hover:cursor-pointer duration-[0.3s] py-[8px] flex items-center justify-center w-full h-auto"
                          >
                            <div className="w-[95%] h-full flex items-center justify-between">
                              <div className="person-details flex items-center justify-start w-auto h-auto">
                                <div className="person-picture flex items-center justify-center w-[35px] h-[35px] rounded-[50%] overflow-hidden">
                                  {typeof member.picture !== "string" && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                                      />
                                    </svg>
                                  )}
                                  {typeof member.picture === "string" &&
                                    member.picture.length > 0 && (
                                      <img
                                        className="w-full h-full object-cover"
                                        src={member.picture}
                                        alt="person-pdp"
                                      />
                                    )}
                                </div>

                                <div className="person-txt ml-[14px] flex items-center justify-start w-auto h-auto">
                                  <label className="text-[15px] text-[#333] font-[500]">
                                    {member.name}
                                  </label>
                                  <label className="ml-[5px] text-[12.5px] text-gray-500 font-[400]">
                                    @{member.nickname}
                                  </label>
                                </div>
                              </div>

                              <div className="person-selected hidden">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check-circle-fill text-green-700"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                              </div>

                              <div
                                className={`person-already flex items-center justify-end ${
                                  project.members.some(
                                    (obj: member) => obj.id === member.id
                                  )
                                    ? ""
                                    : "hidden"
                                }`}
                              >
                                <label className="text-[13px] italic text-[#00000074] font-[400] mr-[7px]">
                                  Déja membre
                                </label>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 -960 960 960"
                                  className="bi bi-check-circle-fill w-[18px] h-[18px] fill-[#00000074]"
                                >
                                  <path d="M480-106q-77 0-145-29.25t-119.25-80.5Q164.5-267 135.25-335T106-480q0-78 29.25-145.5t80.5-118.75Q267-795.5 335-824.75T480-854q78 0 145.5 29.25t118.75 80.5Q795.5-693 824.75-625.5T854-480q0 77-29.25 145t-80.5 119.25Q693-164.5 625.5-135.25T480-106Zm0-67q127.5 0 217.25-89.75T787-480q0-55-18-103.5T719-672L288-241q40 32 88.5 50T480-173ZM241-288l431-431q-40-32-88.5-50T480-787q-127.5 0-217.25 89.75T173-480q0 55 18 103.5t50 88.5Z" />
                                </svg>
                              </div>
                            </div>
                          </button>
                        ))}
                      </React.Fragment>
                    ) : (
                      <div className="flex items-center justify-start w-[95%] h-auto">
                        <label className="text-gray-400 text-[12px]">
                          Aucun membre dans l'organisation
                        </label>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HandleMembers;
