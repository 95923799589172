import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  DocumentData,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import {
  returnTemplate,
  emailTemplates,
  returnNotificationBody,
} from "./constants";
import { database } from "../Firebase/databaseConfig";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { toast } from "sonner";

const auth = getAuth();

export const markMessageAsSeen = (notifId: string) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach(async (obj) => {
          let user = obj.data();

          const collectionRef = collection(
            database,
            "organisations/" + `${user.organisationId}` + "/notifications/"
          );
          const docRef = doc(collectionRef, notifId);

          await setDoc(docRef, { read: true }, { merge: true });
        });
      });
    }
  });
};

export const renderNotifications = (
  setNotifications: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(
              database,
              "organisations" + `${user.organisationId}` + "notifications"
            )
          );

          onSnapshot(q, async (querySnapshot) => {
            setNotifications([]);
            querySnapshot.forEach((obj) => {
              setNotifications((old) => [...old, obj.data()]);
            });

            setIsLoading(false);
          });
        });
      });
    }
  });
};

export const sendNotification = (body: string) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((obj) => {
          let user = obj.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((res) => {
              let organisation = res.data();

              let id = uuid();

              const docRef = doc(
                database,
                "organisations",
                `${organisation.id}`,
                "notifications",
                `${id}`
              );

              let data = {
                id: id,
                read: false,
                message: body,
                date: new Date(),
              };

              setDoc(docRef, data)
                .then(() => {
                  console.log("notification-successfull-sent");
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          });
        });
      });
    }
  });
};

export const sendEmailToProject = (
  projectId: string,
  reason: string,
  subject: string,
  params: any,
  receivers: string[]
) => {
  onAuthStateChanged(auth, () => {
    const q = query(
      collection(database, "projects"),
      where("id", "==", `${projectId}`)
    );

    onSnapshot(q, async (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let project = doc.data();

        let teams = project.teams;
        let members = project.members;
        let membersMails: string[] = [];

        for (const team of teams) {
          sendEmailToTeam(team.id, reason, subject);
        }

        for (const member of members) {
          const q = query(
            collection(database, "users"),
            where("id", "==", `${member.id}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              membersMails.push(obj.data().email);
            });
          });
        }

        sendMail(reason, membersMails, params);
      });
    });
  });
};

export const sendEmailToTeam = (
  teamId: string,
  reason: string,
  params: any,
  receivers?: string[]
) => {
  onAuthStateChanged(auth, () => {
    const q = query(
      collection(database, "teams"),
      where("id", "==", `${teamId}`)
    );

    onSnapshot(q, async (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let members = doc.data().members;
        let membersMails: string[] = [];
        let membersId = [];

        for (const obj of members) {
          membersId.push(obj.id);
        }

        for (const memberId of membersId) {
          const q = query(
            collection(database, "users"),
            where("id", "==", `${memberId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              membersMails.push(obj.data().email);
            });
          });
        }

        sendMail(reason, membersMails, params);
      });
    });
  });
};

export const sendEmailToProjectAdmins = (
  projectId: any,
  reason: string,
  params: any
) => {
  const q = query(
    collection(database, "projects"),
    where("id", "==", projectId)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let members = obj.data().members;

      for (const member of members) {
        if (member.role === "Manager") {
          sendEmailToPerson(member.id, reason, params);
        }
      }
    });
  });
};

export const sendEmailToPerson = (
  userId: string,
  reason: string,
  params: any
) => {
  const q = query(
    collection(database, "users"),
    where("id", "==", `${userId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      sendMail(reason, [obj.data().email], {
        ...params,
        username: obj.data().name.split(" ")[0],
      });
    });
  });
};

export const sendMail = async (
  reason: string,
  receivers: string[],
  params: any
) => {
  let subject = "";

  let template: string = returnTemplate(reason, params);
  let body: string = returnNotificationBody(reason, params);

  for (const obj of emailTemplates) {
    if (obj.type === reason) {
      subject = obj.subject;
    }
  }

  axios
    .get("https://api.intellifrica.com/send-email", {
      params: {
        subject: subject,
        to: receivers,
        content: template,
      },
    })
    .then((data) => {
      sendNotification(body);
    })
    .catch((error) => {});
};

export const sendMailV2 = async (
  userId: string,
  subject: string,
  message: string
) => {
  const userDocRef = doc(database, "users", userId);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    const userData = userDoc.data();
    let email = userData.email;
    const organisationId = userData.organisationId;

    onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(async function (idToken) {
            console.log(idToken);
            try {
              await axios.post(
                "https://teamask-backend-v1.vercel.app/api/v1/mails/send",
                {
                  orgId: organisationId,
                  to: email,
                  subject: subject,
                  message: message,
                },
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                  },
                }
              );
            } catch (error: any) {
              console.error(error);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  } else {
    console.error("No such document for userId:", userId);
  }
};
