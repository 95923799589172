import React, { useEffect, useState } from "react";
import { DocumentData } from "firebase/firestore";
import TaskSection from "./TaskSection";
import ProjectHeader from "./ProjectHeader";
import DoughnutChart from "./Doughnut";
import "../../index.css";
import SectionLoad from "../Loaders/SectionLoad";
import {
  getProjectTasks,
  getProject,
} from "../../Backend/Projects/handleProject";
import {
  getUserRole,
  getUserRoleInProject,
} from "../../Backend/Security/handleSecurity";
import { useParams } from "react-router-dom";
import HeaderLoad from "../Loaders/HeaderLoad";
import ChartLoader from "../Loaders/ChartLoader";
import CryptoJS from "crypto-js";
import { Toaster } from "sonner";

const key = "2cb59abf-2a42-47c9-a106-557008004d23";

function Project() {
  const params = useParams();
  let id = params.id;

  const [tasks, setTasks] = useState<any>([]);
  const [role, setRole] = useState<string>("");
  const [stats, setStats] = useState<number[]>([]);
  const [toDo, setToDo] = useState<DocumentData[]>([]);
  const [doing, setDoing] = useState<DocumentData[]>([]);
  const [searchTask, setSearchTask] = useState<string>("");
  const [admin, setAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [decryptedId, setDecryptedId] = useState<string>("");
  const [finished, setFinished] = useState<DocumentData[]>([]);
  const [roleInProject, setRoleInproject] = useState<string>("");
  const [project, setProject] = useState<DocumentData | null>(null);
  const [filteredToDo, setFilteredToDo] = useState<DocumentData[]>([]);
  const [filteredDoing, setFilteredDoing] = useState<DocumentData[]>([]);
  const [filteredFinished, setFilteredFinished] = useState<DocumentData[]>([]);

  useEffect(() => {
    setFilteredToDo([]);
    setFilteredDoing([]);
    setFilteredFinished([]);

    const filteredToDo =
      toDo.length !== 0 &&
      searchTask.length !== 0 &&
      toDo.filter(
        (task: any) =>
          task.name &&
          task.name.toLowerCase().includes(searchTask.toLowerCase())
      );

    const filteredDoing =
      doing.length !== 0 &&
      searchTask.length !== 0 &&
      doing.filter(
        (task: any) =>
          task.name &&
          task.name.toLowerCase().includes(searchTask.toLowerCase())
      );

    const filteredFinished =
      finished.length !== 0 &&
      searchTask.length !== 0 &&
      finished.filter(
        (task: any) =>
          task.name &&
          task.name.toLowerCase().includes(searchTask.toLowerCase())
      );

    setFilteredToDo(filteredToDo as DocumentData[]);
    setFilteredDoing(filteredDoing as DocumentData[]);
    setFilteredFinished(filteredFinished as DocumentData[]);

    if (searchTask === "") {
      setFilteredToDo([]);
      setFilteredDoing([]);
      setFilteredFinished([]);
    }
  }, [doing, finished, searchTask, toDo]);

  useEffect(() => {
    if (project !== null) {
      setRole("");
      setRoleInproject("");
      getUserRole(setRole);
      getUserRoleInProject(project.id, setRoleInproject);
    }
  }, [project]);

  useEffect(() => {
    if (role !== "" && roleInProject !== "") {
      if (role === "creator" || role === "manager") {
        setAdmin(true);
      } else {
        if (roleInProject === "Manager") {
          setAdmin(true);
        } else if (roleInProject === "Membre") {
          setAdmin(false);
        }
      }
    }
  }, [role, roleInProject]);

  useEffect(() => {
    console.log(admin);
  }, [admin]);

  useEffect(() => {
    if (id) {
      const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(id), key);
      const decryptedId = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setDecryptedId(decryptedId);
    }

    if (id) getProject(decryptedId, setProject, setIsLoading);
  }, [decryptedId, id]);

  useEffect(() => {
    if (project !== null) {
      getProjectTasks(
        project.id,
        setToDo,
        setDoing,
        setFinished,
        setStats,
        setIsLoading
      );
    }
  }, [project]);

  useEffect(() => {
    if (!arraysAreEqual(tasks, [...toDo, ...doing, ...finished])) {
      setTasks([...toDo, ...doing, ...finished]);
    }
  }, [toDo, doing, finished, tasks]);

  function arraysAreEqual(arr1: DocumentData[], arr2: DocumentData[]) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  return (
    <div className="project flex items-start justify-start flex-col w-full h-auto">
      <Toaster position="top-center" richColors />

      {isLoading ? (
        <HeaderLoad />
      ) : (
        project !== null &&
        admin !== null && (
          <ProjectHeader
            tasks={tasks}
            admin={admin}
            project={project}
            searchTask={searchTask}
            setSearchTask={setSearchTask}
          />
        )
      )}

      <section className="task-section flex items-center justify-center w-full h-auto">
        <div className="for-task-section pt-[0.25em] md:pt-0 overflow-y-auto md:overflow-y-visible snap-mandatory snap-x flex items-start justify-start w-[92.5%] h-auto">
          {/* TASKS SECTION */}
          {isLoading && <SectionLoad />}
          {isLoading && <SectionLoad />}
          {isLoading && <SectionLoad />}
          {isLoading && <ChartLoader />}

          {project !== null && admin !== null && !isLoading && (
            <TaskSection
              admin={admin}
              project={project}
              name="toDo"
              tasks={toDo}
              searchTask={searchTask}
              filteredTasks={filteredToDo}
            />
          )}

          {project !== null && admin !== null && !isLoading && (
            <TaskSection
              admin={admin}
              project={project}
              name="doing"
              tasks={doing}
              searchTask={searchTask}
              filteredTasks={filteredDoing}
            />
          )}

          {project !== null && admin !== null && !isLoading && (
            <TaskSection
              admin={admin}
              project={project}
              name="finished"
              tasks={finished}
              searchTask={searchTask}
              filteredTasks={filteredFinished}
            />
          )}

          {!isLoading && (
            <div className="flex translate-y-[-1.25em] sticky top-0 rounded-t-none border-t-0 items-center justify-center h-auto snap-center min-w-[97.5%] md:min-w-0 md:w-[24%] rounded-[8px] py-[1em] border-[1px] mx-[0.5em] md:mr-[1em] border-[#dadae0] bg-[#F6F6F6]">
              <div className="flex items-start justify-start flex-col w-[95%] h-auto">
                {/* PROGRESS BAR */}
                <div className="circular-progress-bar flex items-center justify-center w-full h-auto">
                  {!isLoading && <DoughnutChart stats={stats} />}
                </div>
                {/* SUMMARY */}
                <div className="tasks-summary flex items-start justify-start flex-col w-full h-auto mt-[0.25em]">
                  <label className="text-[16px] text-[#303030] font-[500] mb-[15px]">
                    Tâches
                  </label>
                  <div className="all-summ flex items-start justify-center flex-wrap w-[full] h-auto">
                    {/*  */}
                    {/* <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
                      <div className="color- flex items-center justify-start w-auto h-auto">
                        <div
                          className={`color bg-all w-[12px] h-[12px] rounded-full mr-[7px]`}
                        ></div>
                        <label className="text-[14px] text-[#333] font-[500]">
                          Toutes les tâches
                        </label>
                      </div>

                      <div className="percentage text-[13px] text-gray-500 font-[400]">
                        <label>100%</label>
                      </div>
                    </div> */}
                    {/*  */}
                    <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
                      <div className="color- flex items-center justify-start w-auto h-auto">
                        <div
                          className={`color bg-toDo w-[12px] h-[12px] rounded-full mr-[7px]`}
                        ></div>
                        <label className="text-[14px] text-[#333] font-[500]">
                          Tâches à faire
                        </label>
                      </div>

                      <div className="percentage text-[13px] text-gray-500 font-[400]">
                        <label>
                          {Number.isNaN(Math.trunc((stats[1] * 100) / stats[0]))
                            ? "0"
                            : Math.trunc((stats[1] * 100) / stats[0])}
                          %
                        </label>
                      </div>
                    </div>
                    {/*  */}
                    <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
                      <div className="color- flex items-center justify-start w-auto h-auto">
                        <div
                          className={`color bg-doing w-[12px] h-[12px] rounded-full mr-[7px]`}
                        ></div>
                        <label className="text-[14px] text-[#333] font-[500]">
                          Tâches en cours
                        </label>
                      </div>

                      <div className="percentage text-[13px] text-gray-500 font-[400]">
                        <label>
                          {Number.isNaN(Math.trunc((stats[2] * 100) / stats[0]))
                            ? "0"
                            : Math.trunc((stats[2] * 100) / stats[0])}
                          %
                        </label>
                      </div>
                    </div>
                    {/*  */}
                    <div className="common-for-detail mb-[12px] flex items-center justify-between w-[95%] h-auto">
                      <div className="color- flex items-center justify-start w-auto h-auto">
                        <div
                          className={`color bg-finished w-[12px] h-[12px] rounded-full mr-[7px]`}
                        ></div>
                        <label className="text-[14px] text-[#333] font-[500]">
                          Tâches terminées
                        </label>
                      </div>

                      <div className="percentage text-[13px] text-gray-500 font-[400]">
                        <label>
                          {Number.isNaN(Math.trunc((stats[3] * 100) / stats[0]))
                            ? "0"
                            : Math.trunc((stats[3] * 100) / stats[0])}
                          %
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <TaskSection /> */}
        </div>
      </section>
    </div>
  );
}

export default Project;
