import {
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { ConfirmationResult } from "firebase/auth";
import {
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { sendEmailToPerson, sendMailV2 } from "../Mails/handleNotifications";
import { app, database } from "../Firebase/databaseConfig";
import { v4 as uuid } from "uuid";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";

type popup = {
  text: string;
  state: string;
};

const auth = getAuth(app);
auth.useDeviceLanguage();

export const authentication = (
  email: string,
  password: string,
  setState: React.Dispatch<React.SetStateAction<string>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  // setPopup: React.Dispatch<React.SetStateAction<popup>>,
  // setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  // setState: React.Dispatch<React.SetStateAction<string>>
) => {
  setIsLoading(true);
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Notify user that someone accessed to his account
      auth.currentUser &&
        sendMailV2(
          auth.currentUser?.uid,
          "Activité sur votre compte",
          "Une personne vient de se connecter à votre compte. S'il s'agit de vous, ignorez ce mail. Si ce n'est pas vous, signalez à votre administrateur cette intrusion et changer de mot de passe dans Paramètres > [BUTTON] Changer le mot de passe"
        );
    })
    .catch((error) => {
      const errorCode = error.code;

      if (
        errorCode === "auth/wrong-password" ||
        errorCode === "auth/invalid-email" ||
        errorCode === "auth/invalid-password"
      ) {
        const message = "Les informations saisies sont incorrectes.";
        toast.error(message);
      }

      if (errorCode === "auth/user-not-found") {
        setState("create-acc");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const verifyAccount = (
  number: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setState: React.Dispatch<React.SetStateAction<string>>
) => {
  setIsLoading(true);

  const q = query(
    collection(database, "users"),
    where("number", "==", `${number}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    if (!querySnapshot.empty) {
      const recaptchaParameters = {
        size: "invisible",
        callback: (response: any) => {},
      };

      const recaptcha: RecaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        recaptchaParameters
      );

      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider
        .verifyPhoneNumber(number, recaptcha)
        .catch((error) => {
          console.error(error);
        });

      if (verificationId) {
        const verificationCode = prompt(
          `Entrez le code de vérification envoyé au ${number}`
        );

        if (verificationCode !== null) {
          const credential = PhoneAuthProvider.credential(
            verificationId,
            verificationCode
          );

          await signInWithCredential(auth, credential)
            .then(async (userCredential) => {
              let user = userCredential.user;
              const response = await fetch(
                "https://api.ipify.org/?format=json"
              );
              await response.json().then(async (data) => {
                const res = await fetch(
                  `https://freeipapi.com/api/json/${data.ip}`
                );

                await res.json().then((obj) => {
                  sendEmailToPerson(user.uid, "recent-login", {
                    loginDate: new Date(),
                    loginDevice: `${navigator.userAgent}, ${navigator.platform}`,
                    loginLocation: `${obj.countryName}, ${obj.regionName}`,
                    loginIp: data.ip,
                  });
                });
              });
              setIsLoading(false);
              return "access-login";
            })
            .catch(() => {
              setPopup({
                state: "error",
                text: "Code de vérification incorrect.",
              });
              setIsVisible(true);
              setIsLoading(false);
              setState("error");
            });
        }
      }

      // else {
      //   setPopup({
      //     state: "error",
      //     text: "Un problème est survenu.",
      //   });
      //   setIsVisible(true);
      //   setIsLoading(false);
      //   setState("error");
      // }

      setIsLoading(false);
      setState("error");
    } else {
      setIsLoading(false);
      setState("create-acc");
    }
  });
};

export const addUserAccount = (
  id: string,
  name: string,
  nickname: string,
  picture: string,
  email: string,
  number: string,
  orgID: string,
  orgName: string,
  orgActivity: string,
  orgLength: string,
  orgPicture: string,
  choice: string,
  navigate: NavigateFunction
) => {
  const collectionRef = collection(
    database,
    // eslint-disable-next-line no-useless-concat
    "users"
  );
  const docRef = doc(collectionRef, id);

  setDoc(docRef, {
    id: id,
    name: name,
    email: email,
    number: number,
    picture: picture,
    nickname: nickname,
    organisationId: orgID,
    added: new Date(),
    shadow: false,
  }).then(() => {
    if (choice === "create") {
      createOrganisation(
        orgName,
        orgActivity,
        orgLength,
        orgPicture,
        id,
        navigate
      );
    } else if (choice === "join") {
      addMemberToOrg(id, orgID);
    }
  });
};

export const verifyOrganisation = async (
  id: string,
  secret: string,
  setName: React.Dispatch<React.SetStateAction<string>>,
  setPicture: React.Dispatch<React.SetStateAction<string>>,
  setFind: React.Dispatch<React.SetStateAction<string>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  try {
    const q = query(
      collection(database, "organisations"),
      where("id", "==", id)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setPopup({
        state: "error",
        text: "Aucune organisation n'a été trouvée.",
      });
      setIsVisible(true);
      setFind("not-find");
      setIsLoading(false);
      return;
    }

    const organisationDoc = querySnapshot.docs[0]; // Supposons qu'il y a au moins une organisation

    const organisationData = organisationDoc.data();

    if (organisationData.closed) {
      setPopup({
        state: "error",
        text: "Cette organisation est fermée.",
      });
      setIsVisible(true);
      setFind("not-find");
    } else if (organisationData.secret === secret) {
      setName(organisationData.name);
      setPicture(organisationData.picture);
      setFind("finded");
    } else {
      setPopup({
        state: "error",
        text: "Le secret fourni est incorrect.",
      });
      setIsVisible(true);
      setFind("not-find");
    }
  } catch (error) {
    setPopup({
      state: "error",
      text: "Erreur lors de la vérification de l'organisation.",
    });
    setIsVisible(true);
  } finally {
    setIsLoading(false);
  }
};

export const createOrganisation = (
  name: string,
  activity: string,
  length: string,
  picture: string,
  userId: string,
  navigate: NavigateFunction
) => {
  const collectionRef = collection(
    database,
    // eslint-disable-next-line no-useless-concat
    "organisations"
  );
  const docRef = doc(collectionRef, uuid());
  const documentID = docRef.id;
  let secret = uuid().slice(0, 6);

  setDoc(docRef, {
    id: documentID,
    name: name,
    members: [],
    secret: secret,
    length: length,
    creator: userId,
    picture: picture,
    date: new Date(),
    activity: activity,
    closed: false,
  }).then(async () => {
    const userRef = doc(database, "users", userId);

    await setDoc(userRef, { organisationId: documentID }, { merge: true });
    sendMailV2(
      userId,
      "Vous venez de créer votre organisation",
      `Félicitations, vous venez de créer votre organisation "${name}".`
    );
    navigate("/");
  });
};

export const addMemberToOrg = (
  userID: string,
  orgID: string,
  navigate?: NavigateFunction
) => {
  const docRef = doc(database, "organisations", orgID);

  const q = query(
    collection(database, "users"),
    where("id", "==", `${userID}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach(async (doc) => {
      let user = doc.data();

      let data = {
        id: user.id,
        name: user.name,
        picture: user.picture,
        nickname: user.nickname,
        role: "Membre",
        added: user.added,
      };

      await updateDoc(docRef, {
        members: arrayUnion(data),
      }).then(() => {
        navigate && navigate("/");
        sendEmailToPerson(data.id, "added-to-org", {});
      });
    });
  });
};

export const sendVerificationCode = async (
  number: string,
  setConfirmationResults: React.Dispatch<
    React.SetStateAction<ConfirmationResult | null>
  >,
  setRecaptcha: React.Dispatch<
    React.SetStateAction<RecaptchaVerifier | undefined>
  >,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup?: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>,
  setNSendCode?: React.Dispatch<React.SetStateAction<number>>,
  nSendCode?: number
) => {
  setIsLoading(true);

  if (nSendCode !== undefined && nSendCode > 2 && setNSendCode !== undefined) {
    if (setPopup !== undefined && setIsVisible !== undefined) {
      setPopup({
        state: "error",
        text: "Impossible de renvoyer le code.",
      });

      setIsVisible(true);
    }

    setIsLoading(false);
    return;
  }

  const recaptchaParameters = {
    size: "invisible",
    callback: (response: any) => {},
  };

  let containedId = "recaptcha-container";

  if (nSendCode !== undefined && nSendCode > 0 && nSendCode < 3) {
    containedId = `recaptcha-container-${nSendCode}`;
  }

  const recaptcha = new RecaptchaVerifier(
    auth,
    containedId,
    recaptchaParameters
  );

  await signInWithPhoneNumber(auth, number, recaptcha)
    .then((confirmationResult) => {
      setRecaptcha(recaptcha);
      setConfirmationResults(null);
      setConfirmationResults(confirmationResult);

      if (setPopup !== undefined && setIsVisible !== undefined) {
        setPopup({
          state: "success",
          text: "Code envoyé avec succès.",
        });

        setIsVisible(true);
      }

      if (setNSendCode !== undefined && nSendCode !== undefined) {
        setNSendCode(nSendCode + 1);
      }
    })
    .catch((error) => {
      console.log(error);
      if (setPopup !== undefined && setIsVisible !== undefined) {
        setPopup({
          state: "error",
          text: "Erreur lors de l'envoie.",
        });

        setIsVisible(true);
      }

      if (error.code === "auth/quota-exceeded") {
        alert("Service actuellement indisponible");
      }
    });

  setIsLoading(false);
};

export const createUserAccount = (
  name: string,
  nickname: string,
  picture: string,
  email: string,
  password: string,
  number: string,
  orgID: string,
  orgName: string,
  orgActivity: string,
  orgLength: string,
  orgPicture: string,
  choice: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction
) => {
  setIsLoading(true);

  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;

      addUserAccount(
        user.uid,
        name,
        nickname,
        picture,
        email,
        number,
        orgID,
        orgName,
        orgActivity,
        orgLength,
        orgPicture,
        choice,
        navigate
      );

      setIsLoading(false);
    })
    .catch(() => {
      toast.error("Une erreur est survenu lors de la création du compte.");
    })
    .finally(() => {
      setIsLoading(false);
    });
};

// export const phoneCredential = (
//   confirmationResult: any,
//   code: string,
//   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
//   setPopup: React.Dispatch<React.SetStateAction<popup>>,
//   setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
//   name: string,
//   nickname: string,
//   picture: string,
//   email: string,
//   number: string,
//   orgID: string,
//   orgName: string,
//   orgActivity: string,
//   orgLength: string,
//   orgPicture: string,
//   choice: string
// ) => {
//   setIsLoading(true);
//   confirmationResult
//     .confirm(code)
//     .then((result: { user: any }) => {
//       const user = result.user;

//       addUserAccount(
//         user.uid,
//         name,
//         nickname,
//         picture,
//         email,
//         number,
//         orgID,
//         orgName,
//         orgActivity,
//         orgLength,
//         orgPicture,
//         choice
//       );

//       setIsLoading(false);
//     })
//     .catch((error: any) => {
//       setIsLoading(false);

//       setPopup({
//         state: "error",
//         text: "Le code de vérification est incorrect.",
//       });
//       setIsVisible(true);
//     });
// };
