import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
  DocumentData,
  Timestamp,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { markAsFinished, changeTaskStatus } from "../Projects/handleProject";
import {
  sendEmailToPerson,
  sendEmailToProjectAdmins,
  sendMailV2,
} from "../Mails/handleNotifications";
import { database } from "../Firebase/databaseConfig";
import { v4 as uuid } from "uuid";
import { toast } from "sonner";

const auth = getAuth();

function convertTimestampToString(timestamp: Timestamp) {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  );
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  return `${day}/${month}/${year} ${hours}h${minutes}`;
}

export const deleteMessage = (
  userId: string,
  project: DocumentData,
  task: DocumentData,
  msgId: string,
  section?: string
) => {
  const docRef = doc(
    database,
    "projects",
    `${project.id}`,
    "tasks",
    `${task.id}`,
    "chats",
    `${msgId}`
  );
  setDoc(
    docRef,
    {
      deleted: {
        isDeleted: true,
        message: "Ce message a été supprimé.",
      },
    },
    { merge: true }
  ).then(() => {
    if (section === "Justificatifs") {
      if (task.members.some((member: any) => member.id === userId)) {
        cancelProof(userId, project, task, msgId, section);
        changeTaskStatus(project, task, "doing");
      }
    }
  });
};

export const fetchMessages = (
  taskId: string,
  project: DocumentData,
  setProofs: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setDiscussions: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      setIsLoading(true);

      const q = query(
        collection(
          database,
          "projects",
          `${project.id}`,
          "tasks",
          `${taskId}`,
          "chats"
        )
      );

      onSnapshot(q, (querySnapshot) => {
        setProofs([]);
        setDiscussions([]);
        let discussions: DocumentData[] = [];
        querySnapshot.forEach((obj) => {
          discussions.push(obj.data());
        });

        for (const data of discussions) {
          if (data.userId === user.uid) {
            let values = {
              id: data.id,
              author: "Vous",
              type: data.type,
              userId: data.userId,
              media: data.media,
              message: data.message,
              deleted: data.deleted,
              date: convertTimestampToString(data.date),
              timeStamp: data.date,
            };

            if (data.section === "Chat") {
              setDiscussions((old) => [...old, values]);
            } else if (data.section === "Justificatifs") {
              setProofs((old) => [...old, values]);
            }
          } else {
            const q = query(
              collection(database, "users"),
              where("id", "==", `${data.userId}`)
            );

            onSnapshot(q, async (querySnapshot) => {
              querySnapshot.forEach((res) => {
                let values = {
                  id: data.id,
                  type: data.type,
                  userId: data.userId,
                  media: data.media,
                  message: data.message,
                  author: res.data().name,
                  deleted: data.deleted,
                  date: convertTimestampToString(data.date),
                  timeStamp: data.date,
                };

                if (data.section === "Chat") {
                  setDiscussions((old) => [...old, values]);
                } else if (data.section === "Justificatifs") {
                  setProofs((old) => [...old, values]);
                }
              });
            });
          }
        }

        setIsLoading(false);
      });
    }
  });
};

export const attachMedia = (
  project: DocumentData,
  taskId: string,
  media: string,
  section: string
) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const docRef = doc(
        database,
        "projects",
        `${project.id}`,
        "tasks",
        `${taskId}`,
        "chats",
        `${uuid()}`
      );

      await setDoc(docRef, {
        id: docRef.id,
        userId: user.uid,
        date: new Date(),
        media: media,
        type: "media",
        section: section,
      }).then(() => {});
    }
  });
};

export const cancelProof = async (
  userId: string,
  project: DocumentData,
  task: DocumentData,
  msgId: string,
  section?: string,
  deleteMsg?: boolean
) => {
  const members = task.members.map((obj: any) => {
    if (obj.id === userId) {
      return { ...obj, completed: false };
    }
    return obj;
  });

  const taskRef = doc(database, "projects", project.id, "tasks", task.id);

  await setDoc(
    taskRef,
    {
      members: members,
    },
    { merge: true }
  ).then(() => {
    sendMailV2(
      userId,
      "Votre justificatif a été rejeté",
      `Votre justificatif pour la tâche "${task.name}" du projet "${project.name}" a été rejeté. Connectez-vous et rendez-vous sur le chat pour plus d'informations.`
    );
    deleteMsg && deleteMessage(userId, project, task, msgId, section);
  });
};

export const sendMessage = async (
  project: DocumentData,
  taskId: string,
  section: string,
  task: DocumentData,
  admin: boolean,
  type: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  message?: string,
  mediaFile?: File
) => {
  try {
    setIsLoading(true);
    const user = auth.currentUser;
    if (!user) {
      throw new Error("Utilisateur non connecté");
    }

    const storage = getStorage();
    let mediaUrl = null;

    if (mediaFile) {
      const storageRef = ref(
        storage,
        `projects/${project.id}/tasks/${taskId}/${mediaFile.name}`
      );

      const snapshot = await uploadBytes(storageRef, mediaFile);

      mediaUrl = await getDownloadURL(snapshot.ref);
    }

    const docRef = doc(
      database,
      "projects",
      project.id,
      "tasks",
      taskId,
      "chats",
      uuid()
    );

    let finalType = type;
    let finalMsg: string | null = message && message !== "" ? message : null;

    if (!admin && mediaUrl && section === "Justificatifs") {
      finalType = "both";
      finalMsg = "J'ai terminé ma tâche et mon justificatif est le suivant:";
    }

    await setDoc(docRef, {
      type: finalType,
      id: docRef.id,
      userId: user.uid,
      date: new Date(),
      section: section,
      deleted: {
        isDeleted: false,
        message: "Ce message a été supprimé.",
      },
      media: mediaUrl || null,
      message: finalMsg,
    });

    if (section === "Justificatifs" && mediaUrl && !admin) {
      const members = task.members.map((obj: any) => {
        if (obj.id === user.uid) {
          return { ...obj, completed: true };
        }
        return obj;
      });

      const taskRef = doc(database, "projects", project.id, "tasks", taskId);

      await setDoc(taskRef, { members: members }, { merge: true });

      toast.success("Vous venez de justifier votre tâche.");

      await sendEmailToProjectAdmins(project.id, "added-proof", {
        taskName: task.name,
        reason: "added-proof",
        projectName: project.name,
      });

      const taskData = (await getDoc(taskRef)).data();
      const taskFinished =
        taskData && taskData.members.every((obj: any) => obj.completed);

      if (taskFinished) {
        await markAsFinished(project.id, task.id);
      }
    }
  } catch (error) {
    console.error("Erreur lors de l'envoi du message:", error);
    toast.error(
      `Une erreur s'est produite lors de l'envoi du message. ${error}`
    );
  } finally {
    setIsLoading(false);
  }
};
